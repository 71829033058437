var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-cd-note",
          attrs: {
            width: "90%",
            title: "Select Invoice",
            visible: _vm.dialogSelectInvoice,
          },
          on: {
            open: _vm.handleSelectInvoiceDialogOpen,
            close: _vm.handleInvoiceDialogClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isInvoiceTableLoading,
                      expression: "isInvoiceTableLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.invoiceTableData,
                    height: "48vh",
                    "header-cell-style": { color: "#909399" },
                    "cell-style": { padding: "0", height: "60px" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "55" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: scope.row },
                                on: {
                                  change: _vm.handleInvoiceRadioSelectChange,
                                },
                                model: {
                                  value: _vm.invoiceRadioData,
                                  callback: function ($$v) {
                                    _vm.invoiceRadioData = $$v
                                  },
                                  expression: "invoiceRadioData",
                                },
                              },
                              [_c("i")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Invoice No.",
                      prop: "userEnteredInvoiceNo",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Company", "show-overflow-tooltip": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isSellerView
                                    ? scope.row.buyerCompanyName
                                    : scope.row.sellerCompanyName
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Vessel/ Terminal",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.orderVesselName
                                    ? scope.row.orderVesselName
                                    : scope.row.orderTerminal
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Invoice Date" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    new Date(scope.row.invoiceDate)
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "el-dialog__footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.handleConfirmInvoiceClick },
                },
                [_vm._v(" Confirm ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { outline: "", round: "" },
                  on: { click: _vm.handleInvoiceDialogClose },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }